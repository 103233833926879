// import React from 'react'

// export default function DifferenceSection() {
//   return (
//     <div>
//       diffrence
//     </div>
//   )
// }

// import React from "react";
// import { Container, Row, Col, Table } from "react-bootstrap";
// // import "bootstrap/dist/css/bootstrap.min.css";
// import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

// const JaavaPotPage = () => {
//   const features = [
//     {
//       title: "Freshness Guarantee",
//       description:
//         "Do your teas remain fresh for more than a year with the aroma and flavor of teas remaining intact.",
//     },
//     {
//       title: "Premium Quality",
//       description:
//         "Sourced from the best estates ensuring the finest quality in every sip.",
//     },
//     {
//       title: "Health Benefits",
//       description:
//         "Packed with antioxidants to promote a healthy lifestyle.",
//     },
//     {
//       title: "Direct Sourcing",
//       description:
//         "Ethically sourced directly from farmers to ensure fair trade.",
//     },
//     {
//       title: "Eco-Friendly Packaging",
//       description:
//         "Sustainable packaging to preserve the environment.",
//     },
//     {
//       title: "Exceptional Taste",
//       description:
//         "Unparalleled flavor in every cup for a delightful experience.",
//     },
//   ];

//   return (
//     <Container className="my-5">
//       {/* Headings */}
//       <Row className="text-center mb-4">
//         <Col>
//           <h1>The JaavaPot Difference</h1>
//           <h3 className="mt-3">The JaavaPot Connects Health In FOOD</h3>
//           <p className="mt-2">
//             Not to distributors. Not to resellers. But to Healthy life.
//           </p>
//         </Col>
//       </Row>

//       {/* Table */}
//       <Table bordered className="mt-4">
//         <thead>
//           <tr>
//             <th style={{ width: "60%" }}>Features</th>
//             <th>The JaavaPot</th>
//             <th>Other Brand</th>
//           </tr>
//         </thead>
//         <tbody>
//           {features.map((feature, index) => (
//             <tr key={index}>
//               <td>
//                 <strong>{feature.title}</strong>
//                 <p className="mt-2">{feature.description}</p>
//               </td>
//               <td className="text-center text-orange">
//                 <FaCheckCircle color="#FD7E4B" size={24} />
//               </td>
//               <td className="text-center text-black">
//                 <FaTimesCircle color="#000" size={24} />
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>
//     </Container>
//   );
// };

// export default JaavaPotPage;


import React from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaCheckCircle } from "react-icons/fa";
import {  BiXCircle } from "react-icons/bi";
import { myColors } from "../../../../contants/Colors";

const JaavaPotPage = () => {
  const features = [
    {
      title: "Freshness Guarantee",
      description:
        "Do your food remain fresh with the aroma and flavor of teas remaining intact.",
    },
    {
      title: "Premium Quality",
      description:
        "Sourced from the best estates ensuring the finest quality in every sip.",
    },
    {
      title: "Health Benefits",
      description:
        "Packed with antioxidants to promote a healthy lifestyle.",
    },
    {
      title: "Direct Sourcing",
      description:
        "Ethically sourced directly from farmers to ensure fair trade.",
    },
    {
      title: "Eco-Friendly Packaging",
      description:
        "Sustainable packaging to preserve the environment.",
    },
    {
      title: "Exceptional Taste",
      description:
        "Unparalleled flavor in every bowl for a delightful experience.",
    },
  ];

  return (
    <Container className="my-5">
      {/* Headings */}
      <Row className="text-center mb-4">
        <Col>
          <h1 style={{ fontFamily: "Arial, sans-serif", fontWeight: "600" }}>
            The JaavaPot Difference
          </h1>
          <h5
            style={{
              fontFamily: "Georgia, serif",
              fontWeight: "300",
              color: "#555",
              marginTop: "1rem",
            }}
          >
            {/* The JaavaPot Connects Health With FOOD */}
            THE JAAVAPOT CONNECTS HEALTH WITH FOOD
          </h5>
          <p
            style={{
              fontFamily: "Verdana, sans-serif",
              fontSize: "1.1rem",
              color: "#777",
              marginTop: "0.5rem",
            }}
          >
            Not to distributors. Not to resellers. But to Healthy life.
          </p>
        </Col>
      </Row>

      {/* Table */}
      <Table bordered className="mt-4" style={{ textAlign: "center" }}>
        <thead>
          <tr style={{ backgroundColor: "#f8f9fa" }}>
            <th
              style={{
                width: "60%",
                fontWeight: "700",
                padding: "1rem",
                fontFamily: "Arial, sans-serif",
                fontSize: "1.2rem",
                textAlign:'initial'
              }}
            >
           
            </th>
            <th
              style={{
                fontWeight: "700",
                padding: "1rem",
                fontFamily: "Arial, sans-serif",
                fontSize: "1rem",
                color:myColors.primaryOrange
              }}
            >
              THE JAAVAPOT
            </th>
            <th
              style={{
                fontWeight: "700",
                padding: "1rem",
                fontFamily: "Arial, sans-serif",
                fontSize: "1rem",
                color:myColors.thirdGray
              }}
            >
              OTHER BRANDS
            </th>
          </tr>
        </thead>
        <tbody>
          {features.map((feature, index) => (
            <tr key={index}>
              <td
                style={{
                  verticalAlign: "middle",
                  padding: "1.5rem",
                  fontFamily: "Verdana, sans-serif",
                  fontSize: "1rem",
                  lineHeight: "1.5",
                  color: "#333",
                  textAlign: "left",
                }}
              >
                 <p
                  style={{
                    marginTop: "0.5rem",
                    fontSize: "1.2rem",
                    fontWeight:'600',
                    color: myColors.primaryNavy,
                  }}
                >
                  {feature.title}
                  </p> 
                <p
                  style={{
                    marginTop: "0.5rem",
                    fontSize: "0.95rem",
                    color: "#555",
                  }}
                >
                  {feature.description}
                </p>
              </td>
              <td
                style={{
                  verticalAlign: "middle",
                  padding: "1.5rem",
                  color: "#FD7E4B",
                }}
              >
                <FaCheckCircle size={28} />
              </td>
              <td
                style={{
                  verticalAlign: "middle",
                  padding: "1.5rem",
                  color: "#000",
                }}
              >
                <BiXCircle size={28} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default JaavaPotPage;
