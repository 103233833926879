// import React from 'react'

// export default function TopSectionNew() {
//   return (
//     <div>
//       top Section
//     </div>
//   )
// }


// import React,{useState,useEffect} from 'react';
// import { Container } from 'react-bootstrap';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/autoplay';
// import { Navigation, Autoplay } from 'swiper';
// import { useNavigate } from 'react-router-dom';




// const TopSectionNew = () => {
//   const [currentSlide, setCurrentSlide] = useState(0);

//   const navigate = useNavigate();

//   const slides = [
//     // { imgSrc: indi1, title: 'One Meal a Day With Millets' },
//     // { imgSrc: indi2, title: 'Products' },
//     // { imgSrc: indi3, title: 'Franchisee Stores' },
//     // { imgSrc: indi4, title: 'Franchisee Stores' },
//     // { imgSrc: poster01, title: 'Franchisee Stores' },
//     // { imgSrc: poster02, title: 'franchisee Stores' },
//     // { imgSrc: "https://surveyappanswers.blob.core.windows.net/jaavapot/Poster%204.png", title: 'franchisee Stores' },
//     { imgSrc: "https://surveyappanswers.blob.core.windows.net/jaavapot/duh.png", title: 'franchisee Stores' },
    
//     { imgSrc: "https://surveyappanswers.blob.core.windows.net/jaavapot/Poster%202.png", title: 'franchisee Stores' },
//     // { imgSrc: "https://surveyappanswers.blob.core.windows.net/jaavapot/poster%203.png", title: 'Franchisee Stores' },
//     { imgSrc: "https://surveyappanswers.blob.core.windows.net/jaavapot/duh.png", title: 'franchisee Stores' },
//   ];

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
//     }, 3000); // Change slide every 3 seconds
//     return () => clearInterval(interval);
//   }, [slides.length]);

//   return (
//     <div className="carousel" style={{ height: '100vh', width: '100vw', overflow: 'hidden',backgroundColor:"#FDF0DD" }}>
//       <div
//         className="slides"
//         style={{
//           display: 'flex',
//           transform: `translateX(-${currentSlide * 100}vw)`,
//           transition: 'transform 0.5s ease-in-out',
//         }}
//       >
//         {slides.map((slide, index) => (
//           <div
//             key={index}
//             style={{
//               minWidth: '100vw',
//               height: '86vh',
//               scale:"1",
//               cursor: 'pointer', 
//               background: `url(${slide.imgSrc}) center/cover no-repeat`,
//             }}
//             onClick={() => navigate('/milletproducts')} 
//           >
//              {/* <img
//     src={slide.imgSrc} // Add the image source here
//     alt={slide.title} // Add alt text for accessibility
//     style={{
//       position: 'absolute',
//       bottom: '20%',
//       left: '70%',
//       width: '500px', // Adjust the size of the image as needed
//       height: 'auto',
//     }}
//   /> */}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default TopSectionNew;


// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import './TopSectionNew.css'; // CSS for styling

// const slides = [
//   {
//     imgSrc: "https://surveyappanswers.blob.core.windows.net/jaavapot/duh.png",
//     heading: "Nutritional Benefits\nFor Your Health",
//     paragraph: "Discover the power of millet nutrition. Packed with vitamins, minerals, and antioxidants, it supports overall well-being.",
//     slideName: "Nutritional Boost",
//   },
//   {
//     imgSrc: "https://surveyappanswers.blob.core.windows.net/jaavapot/Poster%202.png",
//     heading: "Regulate Blood Sugar\nNaturally",
//     paragraph: "Millets are low-glycemic, making them ideal for managing blood sugar levels and preventing spikes.",
//     slideName: "Blood Sugar Regulation",
//   },
//   {
//     imgSrc: "https://surveyappanswers.blob.core.windows.net/jaavapot/duh.png",
//     heading: "Promote A Healthy\nHeart",
//     paragraph: "Rich in fiber and essential nutrients, millets contribute to improved cardiovascular health.",
//     slideName: "Heart Health",
//   },
//   {
//     imgSrc: "https://surveyappanswers.blob.core.windows.net/jaavapot/duh.png",
//     heading: "Support Weight\nManagement",
//     paragraph: "Stay fit and healthy with millets. High in fiber and protein, they keep you fuller for longer.",
//     slideName: "Weight Management",
//   },
//   {
//     imgSrc: "https://surveyappanswers.blob.core.windows.net/jaavapot/Poster%202.png",
//     heading: "Go Green with\nSustainability",
//     paragraph: "Millets require less water and are environmentally friendly. A step towards sustainable living.",
//     slideName: "Sustainability",
//   },
// ];

// const TopSectionNew = () => {
//   const [currentSlide, setCurrentSlide] = useState(0);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
//     }, 3000); // Change slide every 3 seconds
//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <div className="carousel" style={{ height: '100vh', width: '100vw', overflow: 'hidden', backgroundColor: "#FDF0DD" }}>
//       <div
//         className="slides"
//         style={{
//           display: 'flex',
//           transform: `translateX(-${currentSlide * 100}vw)`,
//           transition: 'transform 0.5s ease-in-out',
//         }}
//       >
//         {slides.map((slide, index) => (
//           <div
//             key={index}
//             className="slide"
//             style={{
//               minWidth: '100vw',
//               height: '86vh',
//               background: `url(${slide.imgSrc}) center/cover no-repeat`,
//               display: 'flex',
//               justifyContent: 'center',
//               alignItems: 'center',
//               position: 'relative',
//             }}
//             onClick={() => navigate('/milletproducts')}
//           >
//             <div className="content">
//               <h2>{slide.heading}</h2>
//               <p>{slide.paragraph}</p>
//             </div>
//           </div>
//         ))}
//       </div>
//       <div className="slide-titles">
//         {slides.map((slide, index) => (
//           <div
//             key={index}
//             className={`title ${index === currentSlide ? 'active' : ''}`}
//           >
//             <div className="circle"></div>
//             <span>{slide.slideName}</span>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default TopSectionNew;

//22-11-24
// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import './TopSectionNew.css';
// import slideImage1 from '../../../../assets/home/topSection/HeroBackground.png';
// import slideImage2 from '../../../../assets/home/topSection/HeroBackground.png';
// import slideImage3 from '../../../../assets/home/topSection/HeroBackground.png';
// import slideImage4 from '../../../../assets/home/topSection/HeroBackground.png';
// import slideImage5 from '../../../../assets/home/topSection/HeroBackground.png';

// const slides = [
//   {
//     imgSrc: slideImage1,
//     heading: "Nutritional Benefits\nFor Your Health",
//     paragraph: "Discover the power of millet nutrition.\nPacked with vitamins, minerals, and antioxidants.\nSupports overall well-being.",
//     slideName: "Nutritional Boost",
//   },
//   {
//     imgSrc: slideImage2,
//     heading: "Regulate Blood Sugar\nNaturally",
//     paragraph: "Millets are low-glycemic.\nIdeal for managing blood sugar levels.\nPrevents spikes effectively.",
//     slideName: "Blood Sugar Regulation",
//   },
//   {
//     imgSrc: slideImage3,
//     heading: "Promote A Healthy\nHeart",
//     paragraph: "Rich in fiber and essential nutrients.\nMillets contribute to improved heart health.\nStay heart-healthy naturally.",
//     slideName: "Heart Health",
//   },
//   {
//     imgSrc: slideImage4,
//     heading: "Support Weight\nManagement",
//     paragraph: "High in fiber and protein.\nKeeps you fuller for longer.\nSupports a healthy weight.",
//     slideName: "Weight Management",
//   },
//   {
//     imgSrc: slideImage5,
//     heading: "Go Green with\nSustainability",
//     paragraph: "Millets require less water.\nEnvironmentally friendly and sustainable.\nA step towards green living.",
//     slideName: "Sustainability",
//   },
// ];

// const TopSectionNew = () => {
//   const [currentSlide, setCurrentSlide] = useState(0);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
//     }, 3000); // Change slide every 3 seconds
//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <div className="carousel" style={{ height: '100vh', width: '100vw', overflow: 'hidden', backgroundColor: "#FDF0DD" }}>
//       <div
//         className="slides"
//         style={{
//           display: 'flex',
//           transform: `translateX(-${currentSlide * 100}vw)`,
//           transition: 'transform 0.5s ease-in-out',
//         }}
//       >
//         {slides.map((slide, index) => (
//           <div
//             key={index}
//             className="slide"
//             style={{
//               minWidth: '100vw',
//               height: '86vh',
//               background: `url(${slide.imgSrc}) center/cover no-repeat`,
//               display: 'flex',
//               justifyContent: 'center',
//               alignItems: 'center',
//               position: 'relative',
//             }}
//             onClick={() => navigate('/milletproducts')}
//           >
//             <div className="content">
//               <h2>{slide.heading}</h2>
//               <p>{slide.paragraph}</p>
//             </div>
//           </div>
//         ))}
//       </div>
//       <div className="slide-titles" style={{ background: `url('../../../../assets/home/topSection/bgOne.png')` }}>
//         {slides.map((slide, index) => (
//           <div
//             key={index}
//             className={`title ${index === currentSlide ? 'active' : ''}`}
//           >
//             <div className="circle"></div>
//             <span>{slide.slideName}</span>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default TopSectionNew;


import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './TopSectionNew.css';
import slideImage1 from '../../../../assets/home/topSection/HeroBackground.png';
import slideImage2 from '../../../../assets/home/topSection/HeroBackground.png';
import slideImage3 from '../../../../assets/home/topSection/HeroBackground.png';
import slideImage4 from '../../../../assets/home/topSection/HeroBackground.png';
import slideImage5 from '../../../../assets/home/topSection/HeroBackground.png';
import bgOne from '../../../../assets/home/topSection/bgOne.png';

const slides = [
  {
    imgSrc: slideImage1,
    heading: "Nutritional Benefits\nFor Your Health",
    paragraph: "Discover the power of millet nutrition.\nPacked with vitamins, minerals, and antioxidants.\nSupports overall well-being.",
    slideName: "Nutritional Boost",
  },
  {
    imgSrc: slideImage2,
    heading: "Regulate Blood Sugar\nNaturally",
    paragraph: "Millets are low-glycemic.\nIdeal for managing blood sugar levels.\nPrevents spikes effectively.",
    slideName: "Blood Sugar Regulation",
  },
  {
    imgSrc: slideImage3,
    heading: "Promote A Healthy\nHeart",
    paragraph: "Rich in fiber and essential nutrients.\nMillets contribute to improved heart health.\nStay heart-healthy naturally.",
    slideName: "Heart Health",
  },
  {
    imgSrc: slideImage4,
    heading: "Support Weight\nManagement",
    paragraph: "High in fiber and protein.\nKeeps you fuller for longer.\nSupports a healthy weight.",
    slideName: "Weight Management",
  },
  {
    imgSrc: slideImage5,
    heading: "Go Green with\nSustainability",
    paragraph: "Millets require less water.\nEnvironmentally friendly and sustainable.\nA step towards green living.",
    slideName: "Sustainability",
  },
];

const TopSectionNew = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 3000); // Change slide every 3 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="carousel" style={{ height: '100vh', width: '100vw', overflow: 'hidden',
     backgroundColor: "#FDF0DD"
      }}>
      <div
        className="slides"
        style={{
          display: 'flex',
          transform: `translateX(-${currentSlide * 100}vw)`,
          transition: 'transform 0.5s ease-in-out',
        }}
      >
        {slides.map((slide, index) => (
          <div
            key={index}
            className="slide"
            style={{
              // minWidth: '100%',
              minWidth: '100vw',
              height: '74vh',
              background: `url(${slide.imgSrc}) center/contain no-repeat`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              // objectFit:'contain',
            }}
            onClick={() => navigate('/milletproducts')}
          >
            <div className="content">
              <p style={{fontFamily:'Karantina',fontSize:'50px',fontWeight:'700',color:'#FFFFFF'}}>{slide.heading}</p>
              <p style={{fontFamily:'Arial',fontSize:'25px',fontWeight:'400',color:'#FFFFFF'}}>{slide.paragraph}</p>
            </div>
          </div>
        ))}
      </div>
      <div
        className="slide-titles"
        style={{
          backgroundImage: `url(${bgOne})`,
          // backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`slide-title ${index === currentSlide ? 'active' : ''}`}
          >
            <div className="circle"></div>
            <span style={{fontFamily:'Arial',fontSize:'20px',fontWeight:'400',color:'#FFFFFF'}}>{slide.slideName}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopSectionNew;
